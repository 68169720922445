
import {
    defineComponent, reactive, ref
} from 'vue';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    emits: ['close', 'submit', 'success'],
    setup(props, { emit }) {
        const planForm = ref<any>(null);

        const formData = reactive({
            Password: ''
        });

        const rules = {
            Password: [{
                required: true,
                message: WordList.RulePasswordEmpty,
                trigger: 'blur'
            }]
        };

        const close = () => {
            emit('close');
        };
        const submit = () => {
            planForm.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post('v3/web/common/featurePlan/verifyFeaturePassword', formData, [() => {
                    emit('close');
                    emit('success');
                }, false]);
                return true;
            });
        };
        return {
            formData,
            rules,
            close,
            submit,
            planForm
        };
    }
});
