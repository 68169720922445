
import {
    defineComponent, PropType,
    ref, watch
} from 'vue';
import { FeaturePlanType } from './all.type';

export default defineComponent({
    props: {
        selectItem: {
            type: Object as PropType<FeaturePlanType>,
            required: true
        },
        options: {
            type: Object as PropType<FeaturePlanType>,
            required: true
        },
        update: {
            type: Number,
            default: 0
        },
        editFee: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        // 预设方案
        const planItem = ref(props.selectItem);
        // 初始化方案、费用选择
        const choosePlan = ref(['']);
        watch(() => props.selectItem, (val: FeaturePlanType) => {
            planItem.value = val;
            choosePlan.value = [];
            // 存储featureplan ID，已选择置灰
            val.Plan.forEach((item) => {
                choosePlan.value.push(item.ID);
            });
        }, {
            immediate: true,
            deep: true
        });

        const remove = (index: number) => {
            planItem.value.Plan.splice(index, 1);
            planItem.value.Fee.splice(index, 1);
        };

        // 监听外部添加选项
        watch(() => props.update, () => {
            planItem.value.Plan.push({
                ID: ''
            });
            planItem.value.Fee.push({
                UUID: ''
            });
        });

        return {
            planItem,
            remove,
            choosePlan
        };
    }
});
