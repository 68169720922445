
import {
    defineComponent, PropType
} from 'vue';
import {
    list, ListHeaderItem, ListContent,
    filterEmptyCellValue
} from '@/components/common/list';

const headerBilling: Array<ListHeaderItem> = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'SipAccount'
}, {
    label: WordList.ProperAllTextContactDetails,
    name: 'Details',
    type: 'customize'
}];
const headerPlan: Array<ListHeaderItem> = [{
    label: WordList.ProperAllTextInstaller,
    name: 'Installer'
}, {
    label: WordList.TabelCommunitiesName,
    name: 'Location'
}];
type Operate = 'billing' | 'featurePlan';
export default defineComponent({
    components: {
        list
    },
    props: {
        initData: {
            type: Array as PropType< Array<ListContent> >,
            default: () => []
        },
        operateType: {
            type: String as PropType< Operate >,
            default: 'billing'
        }
    },
    emits: ['submit'],
    setup() {
        const headers = {
            billing: headerBilling,
            featurePlan: headerPlan
        };
        const tips = {
            billing: WordList.CommunityUpdatingListTip,
            featurePlan: WordList.ProperAllTextUpdatePlanTips
        };
        return {
            headers,
            filterEmptyCellValue,
            tips
        };
    }
});
