import { ProjectFeatureType } from './all.type';

/**
 * @name 格式化featureplan数据，删除额外参数，留下ID、UUID
 * @param featureData 原始featureplan数据
 */
const formatFeatureData = (featureData: ProjectFeatureType) => {
    const resultObj: ProjectFeatureType = featureData;
    featureData.community.Plan.forEach((planItem, index) => {
        resultObj.community.Plan[index] = { ID: planItem.ID };
    });
    featureData.community.Fee.forEach((feeItem, index) => {
        resultObj.community.Fee[index] = { UUID: feeItem.UUID };
    });
    featureData.office.Plan.forEach((planItem, index) => {
        resultObj.office.Plan[index] = { ID: planItem.ID };
    });
    featureData.office.Fee.forEach((feeItem, index) => {
        resultObj.office.Fee[index] = { UUID: feeItem.UUID };
    });
    return resultObj;
};

/**
 * @name 检查featureplan是否全部填写
 * @param featureData 已存在方案选项
 * @returns [comValid, officeValid],comValid社区方案有效性，officeValid办公方案有效性
 */
const checkFeatureFilled = (featureData: ProjectFeatureType) => {
    let comValid = true;
    let officeValid = true;
    featureData.community.Plan.forEach((item) => {
        if (item.ID === '') {
            comValid = false;
        }
    });
    featureData.office.Plan.forEach((item) => {
        if (item.ID === '') {
            officeValid = false;
        }
    });
    featureData.community.Fee.forEach((item) => {
        if (item.UUID === '') {
            comValid = false;
        }
    });
    featureData.office.Fee.forEach((item) => {
        if (item.UUID === '') {
            officeValid = false;
        }
    });
    return [comValid, officeValid];
};

export default null;
export {
    formatFeatureData,
    checkFeatureFilled
};