
import {
    defineComponent,
    PropType,
    reactive,
    ref,
    Ref
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import updateBillingList from '@/components/view/common/dialog/update-billing-list';
import featurePlanSelect from '@/components/view/super/feature-plan-select';
import HttpRequest from '@/util/axios.config';
import { timeZone, update } from '@/data';
import { user } from '@/methods/rule';
import { deepCopyObject } from '@/methods/basicFun';
import noticeBase from '@/util/notice.base';
import editFeaturePlanDialog from '@/components/view/super/edit-feature-plan-dialog';
import { FormData } from './all.type';
import { formatFeatureData, checkFeatureFilled } from './util';

const { timeZoneOptions } = timeZone;
const rules = {
    Account: [{
        required: true,
        message: WordList.RuleAccountEmpty,
        trigger: 'blur'
    }, {
        validator: user.checkManageAccount,
        trigger: 'blur'
    }],
    Email: [{
        validator: user.checkEmail,
        trigger: 'blur'
    }]
};

export default defineComponent({
    components: {
        dialogShell,
        featurePlanSelect,
        updateBillingList,
        editFeaturePlanDialog
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: `${WordList.ProperAllTextNew} ${WordList.ProperAllTextRoleDistributor}`,
            edit: `${WordList.TabelConfigInHtmlModify} ${WordList.ProperAllTextRoleDistributor}`
        };
        const urls = {
            add: 'v3/web/common/distributor/add',
            edit: 'v3/web/common/distributor/edit'
        };
        const formRef: Ref<any> = ref(null);

        const formData = reactive({ ...props.initForm });
        const officeFeatureData = reactive(formData.ProjectFeature.office);
        const officeFeatureOptions = reactive(formData.ProjectFeatureOptions.office);
        const communityFeatureData = reactive(formData.ProjectFeature.community);
        const communityFeatureOptions = reactive(formData.ProjectFeatureOptions.community);
        const showComFeatureTips = ref(false);
        const showOfficeFeatureTips = ref(false);
        // 检查featureplan选项是否填写
        const checkFeaturePlanFill = () => {
            showComFeatureTips.value = false;
            showOfficeFeatureTips.value = false;
            const [comValid, officeValid] = checkFeatureFilled(formData.ProjectFeature);
            showComFeatureTips.value = !comValid;
            showOfficeFeatureTips.value = !officeValid;
            return comValid && officeValid;
        };

        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                if (!checkFeaturePlanFill()) return false;
                const disParams = deepCopyObject(formData);
                Object.keys(disParams).forEach((item) => {
                    const key = item as keyof FormData;
                    if (key === 'ProjectFeature') {
                        disParams[key] = formatFeatureData(disParams[key]);
                    } else if (key === 'ProjectFeatureOptions') {
                        delete disParams[key];
                    }
                });
                HttpRequest.post(urls[props.operaType], disParams, [(data: { msg: string }) => {
                    if (props.operaType === 'add') noticeBase.messageBox('alert', data.msg, ' ', 'success')(() => false);
                    else noticeBase.message(data.msg);
                    emit('success');
                    emit('close');
                }, false]);
                return true;
            });
        };

        const resetPw = () => {
            HttpRequest.post('v3/web/common/manage/resetPwd', {
                ID: formData.ID
            }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, ' ', 'success')(() => false);
            }, false]);
        };

        const updatePlan = ref(0);
        const updateOfficePlan = ref(0);

        // 显示update feature plan 弹窗
        const showList = ref(false);
        const initData = ref([]);
        // 获取升级社区列表
        // const updateFeaturePlan = () => {
        //     update.getComUpdatePlanList(formData.ID!, update.stepForGetList, (res: {
        //         data: [];
        //     }) => {
        //         initData.value = res.data;
        //         showList.value = true;
        //     }, false);
        // };
        // 确认刷新高级功能方案
        const confirmUpdate = () => {
            update.getComUpdatePlanList(formData.ID!, update.stepForUpdate, () => {
                showList.value = false;
            }, true);
        };

        const showEditPlanDialog = ref(false);
        const editFeaturePlan = () => {
            showEditPlanDialog.value = true;
        };

        const isEditFee = ref(false);

        return {
            titles,
            formData,
            langOptions,
            rules,
            timeZoneOptions,
            formRef,
            submit,
            resetPw,
            updatePlan,
            showComFeatureTips,
            showOfficeFeatureTips,
            showList,
            initData,
            confirmUpdate,
            officeFeatureData,
            officeFeatureOptions,
            updateOfficePlan,
            communityFeatureData,
            communityFeatureOptions,
            editFeaturePlan,
            showEditPlanDialog,
            isEditFee
        };
    }
});
