// 遍历对象，深拷贝
const deepCopyObject = (obj: {
    [key in string | number | symbol]: any;
}) => {
    let newObj: {
        [key in string | number | symbol]: any;
    } = {};
    if (Array.isArray(obj)) newObj = [];
    Object.keys(obj).forEach((ele) => {
        const value = obj[ele];
        if (typeof value === 'object' && !!value) {
            newObj[ele] = deepCopyObject(value);
        } else {
            newObj[ele] = value;
        }
    });

    return newObj;
};

function isElement(el: HTMLElement | null): el is HTMLElement {
    return el !== null;
}

const limitErrorImg = (el: any) => {
    el.addEventListener('error', (event: any) => {
        event.currentTarget.remove();
    });
};

export default null;
export {
    deepCopyObject,
    isElement,
    limitErrorImg
};